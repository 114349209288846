import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import PageTitle from '../components/PageTitle'
import MaxWidth from '../components/MaxWidth'
import Layout from '../components/Layout'

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      <title>404 - Page Not Found</title>
      <meta name="description" content="Page not found" />
    </Helmet>

    <MaxWidth>
      <PageTitle>Page Not Found</PageTitle>
      <p>
        Please return <Link to="/">home</Link> or use the menu to navigate to a
        different page.
      </p>
    </MaxWidth>
  </Layout>
)

export default NotFoundPage
